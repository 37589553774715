<template>     
        
    <div class="content-onglets table">       
        
        <ConclusionElement :item="data" type="detail"/>

        <!-- Iqwig Section start-->
        <template v-if="$route.params.agency == 'iqwig'">
            <table class="comparateur2" v-if="data.iqwig.iqwig_extent_probability.length > 0">
                <thead>
                    <tr>
                        <th width="34%" class="gray" > {{$t("iqwig.target_population")}} </th>
                        <th width="15%" class="gray" > {{$t('iqwig_probability_ab')}} </th>
                        <th width="15%" class="gray"  > {{$t('iqwig_conclusion_ab')}} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value_ab, key_ab) in data.iqwig.iqwig_extent_probability" :key="'key_ab_'+key_ab">
                        <td class="text-left bg-white" >
                            {{check_empty($i18n.locale == 'de' ? value_ab['titlede'] : value_ab['title'])}}
                        </td>
                        <td>
                            {{value_ab.iqwig_probability['name'+$i18n.locale] ? value_ab.iqwig_probability['name'+$i18n.locale] : '-' }}
                        </td>
                        <td>
                            {{value_ab['added_benefit'] ? $t("added_benefit_"+value_ab['added_benefit']) : '-' }}
                        </td>
                    </tr>
                </tbody>

            </table>
        </template>
        <!-- Iqwig Section end-->

        <!-- Cdf Section start-->
        <template v-if="$route.params.agency == 'cdf'">
            <table v-if="data.cdf && ( data.cdf.additional_notes || data.cdf.score_overall || data.cdf.score_quality_of_life || data.cdf.score_toxicity || data.cdf.score_degree  || data.cdf.score_cost  || data.cdf.strength_of_evidence  || data.cdf.total_clinical_score )" class="comparateur2">  
                 <thead>
                    <tr>
                        <th v-if=" data.cdf.progression_free_survival_pfs &&  data.cdf.score &&  data.cdf.progression_free_survival_pfs == 'Yes' " width="12%" class="gray">
                            Progression-free survival (PFS)
                        </th>
                        <th width="12%" class="gray">Overall survival</th>
                        <th width="12%" class="gray">Quality of life</th>
                        <th width="12%" class="gray">Toxicity</th>
                        <th width="12%" class="gray">Clinical unmet need</th>
                        <th width="12%" class="gray">Cost</th>
                        <th width="12%" class="gray">Strength of evidence</th>
                        <th width="12%" class="gray">Total score</th>
                   </tr>
                </thead>
                <tbody>
                    <tr> 
                        <td  v-if=" data.cdf.progression_free_survival_pfs &&  data.cdf.score &&  data.cdf.progression_free_survival_pfs == 'Yes' ">
                            {{ data.cdf.score ? get_value_cdf(data.cdf.score) : '-'}}
                        </td>
                        <td>{{ data.cdf.score_overall ? get_value_cdf(data.cdf.score_overall) : ''}}</td>
                        <td>{{ data.cdf.score_quality_of_life ? get_value_cdf(data.cdf.score_quality_of_life) : '-' }}</td>
                        <td>{{ data.cdf.score_toxicity ? get_value_cdf(data.cdf.score_toxicity) : '-' }}</td>                        
                        <td>{{ data.cdf.score_degree ? get_value_cdf(data.cdf.score_degree) :'-' }}</td>
                        <td>{{ data.cdf.score_cost ? get_value_cdf(data.cdf.score_cost) : '-' }}</td>
                        <td>{{ data.cdf.strength_of_evidence ? get_value_cdf(data.cdf.strength_of_evidence) : '-' }}</td>
                        <td>{{ data.cdf.total_clinical_score ? get_value_cdf(data.cdf.total_clinical_score) : '-'}}</td>
                    </tr>
                </tbody>
            </table>
            <p v-html="data.cdf.additional_notes"></p>
        </template>      
        <!-- Cdf Section end-->

        <!-- Gba Section start-->
        <template v-if="$route.params.agency ==  'gba'">
            <table class="comparateur2" v-if="data.gba['gba_addition_benefits'].length > 0" >
                <thead>
                    <tr>
                        <th width="39%" class="gray">&nbsp;</th>
                        <th width="16.666%" class="gray">Added beneﬁt (AB)</th>
                    </tr>
                </thead>
                <tbody>                    
                    <tr v-for="(value_ab,key_ab) in data.gba['gba_addition_benefits']" :key="'conclusion_gba_ab_'+key_ab">
                        <td class="text-left bg-white">
                            <span v-if="$i18n.locale == 'de'"  class="edit-text" data-type="text" data-model="IqwigExtentProbability" data-field="titlede" :data-pk="value_ab['id']" :data-source="value_ab['titlede']?  value_ab['titlede'] : '-'" :data-title="$t('target_population')">
                                {{value_ab['titlede']  ?  value_ab['titlede'] : "-"}}
                            </span>
                            <span v-else  class="edit-text" data-type="text" data-model="IqwigExtentProbability" data-field="title" :data-pk="value_ab['id']" :data-source="value_ab['title']?  value_ab['title'] : '-'" :data-title="$t('target_population')">
                                {{value_ab['title']  ?  value_ab['title'] : "-"}}
                            </span>
                        </td>
                        <td class="bg-white">
                            <span class="edit-select" data-model="IqwigExtentProbability" data-field="iqwig_probability_id" :data-pk="value_ab['id']" data-source="" :data-title="$t('iqwig_probability_ab')">                                
                                <span v-if="value_ab.gba_added_benefit && value_ab.gba_added_benefit['name'+$i18n.locale]">{{ value_ab.gba_added_benefit['name'+$i18n.locale] }}</span>
                                <span v-else>-</span>
                            </span>
                        </td>
                    </tr>
                    
                </tbody>
            </table>

        </template>
        <!-- Gba Section end-->

        <div v-html="get_rationale_commentary(data)">
        </div>

        <template v-if="data.agency_model == 'Nice' && data.nice && data.nice.comment">            
            <p style="margin-bottom: 0"><strong class="text-orange">{{$t('NICE_commentaires')}}</strong></p>
            <span v-html="data.nice.comment"></span>
        </template>

        <!-- Transparence -->
        <template v-if="$route.params.agency == 'trs'">
            <div style="text-align: justify;" v-if="data.transparence && data.transparence.renewal_conditions">
                {{data.transparence.renewal_conditions ? $t(data.transparence.renewal_conditions) : '-'}}
            </div>

            <table class="comparateur2 text-center" v-if="data.transparence.transparence_smr.length > 0">
                <thead>
                    <tr>
                        <th width="45%" class="gray">&nbsp;</th>
                        <th width="18.33%" class="gray"> {{$t('asmr.ASMR_obtenue_short')}} </th>
                        <th width="18.33%" class="gray"> {{$t('asmr.ASMR_demande_short')}} </th>
                        <th width="18.33%" class="gray"> {{$t('SMR')}} </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item_smr_asmr_conclusion, key_smr_asmr_conclusion) in data.transparence.transparence_smr" :key="'key_smr_asmr_conclusion_'+key_smr_asmr_conclusion">
                        <tr>
                            <td class="text-center">
                                <span  class="edit-text">
                                    {{check_empty($i18n.locale == 'fr' ? item_smr_asmr_conclusion['smr_title'] : item_smr_asmr_conclusion['smr_title_en'])}}
                                </span>
                            </td>
                            <td class="text-center">
                                <span  class="edit-text">
                                    {{check_empty($i18n.locale == 'fr' ? item_smr_asmr_conclusion['asmr_obtenue'] : item_smr_asmr_conclusion['asmr_obtenue_en'])}}
                                </span>
                            </td>
                            <td class="text-center">
                                <span  class="edit-text">
                                    {{item_smr_asmr_conclusion['asmr_demande'] ? item_smr_asmr_conclusion['asmr_demande'] : '-' }}
                                </span>
                            </td>
                            <td class="text-center">
                                <span  class="edit-text">
                                    {{ item_smr_asmr_conclusion['smr_type'] ? $t('transparence.smr.'+item_smr_asmr_conclusion['smr_type']) : '-' }}
                                </span>
                            </td>
                        </tr>
                        <tr v-if="item_smr_asmr_conclusion['asmrcomparateur'] && item_smr_asmr_conclusion['asmrcomparateur'][0] && (item_smr_asmr_conclusion['asmrcomparateur'][0]['text'] || item_smr_asmr_conclusion['asmr_comparateur'] != 'TRS_asmr_0')" :key="'asmrcomparateur_'+key_smr_asmr_conclusion">
                            <td colspan="4" style="padding:0px;">    

                                <table width="100%" style=" margin:0;border:none ">
                                    <tbody>
                                        <tr v-if="item_smr_asmr_conclusion['asmrcomparateur'][0] && item_smr_asmr_conclusion['asmrcomparateur'][0]['text']">
                                            <th class="text-left gray" width="20%"> {{$t('comparaison')}} </th> 
                                            <td width="25%" class="bold text-center" style="vertical-align: middle;"><strong> {{item_smr_asmr_conclusion['asmr_comparateur'] ? $t(item_smr_asmr_conclusion['asmr_comparateur']) : '-'}} </strong></td>   
                                            <td width="55%" class="bold" style="vertical-align: middle;">
                                                <div style="text-align: justify;" v-for="(item_asmr_comparateur, key_asmr_comparateur) in item_smr_asmr_conclusion['asmrcomparateur']" :key="'key_asmr_comparateur_'+key_asmr_comparateur">
                                                        {{item_asmr_comparateur['text'] ? item_asmr_comparateur['text'] : '-'}}
                                                </div>  
                                            </td>   
                                        </tr> 
                                        <tr v-else-if="item_smr_asmr_conclusion['asmr_comparateur'] != 'TRS_asmr_0'">
                                            <th class="text-left gray" width="15%"> {{$t('comparaison')}} </th> 
                                            <td width="61%" class="bold" colspan="2"><strong> {{item_smr_asmr_conclusion['asmr_comparateur'] ? $t(item_smr_asmr_conclusion['asmr_comparateur']) : '-'}} </strong></td>
                                        </tr> 
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </template>

    </div>
</template>

<script>
import { get_rationale_commentary,get_value_cdf, check_empty } from '../../utils';
import ConclusionElement from '../elements/conclusion_element.vue';
export default {
    name : "Conclusion",
    components : {
        ConclusionElement
    },
    computed : {
        data(){            
            return this.$store.getters['detail/data']
        }
    },
    methods : {
        get_rationale_commentary,
        get_value_cdf,
        check_empty
    }
}
</script>

<style scoped>
/* b, strong {
    font-weight: bold; */
/* } */

.fa-angle-up {
    color : #ff6300;
}
.box-toggle .icon-arrow-updown {
    font-size: 16px;
    right: -14px;
    color: #232426;
    margin-left:5px;
}
.box-toggle a:hover i {
    color: #ff6300;
}
table.comparateur2 {
    display: table;
    table-layout: fixed;
}

table.comparateur2 > thead > tr > th, table.comparateur2 > thead > tr > td{
    background: none;
}
.table thead th {
    vertical-align: middle;
}
table.comparateur2 tr td.gray, table.comparateur2 tr th.gray {
    background-color: #e6e9ee;
}
</style>